<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>{{ `รายการเกม ${total}` }}</b-card-title>
        </div>
      </b-card-header>
      <hr class="m-0">

      <!-- Table -->
      <b-table
        :items="dataList"
        :fields="fields"
        :busy="isFetching"
        responsive
        show-empty
        hover
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(date)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(bet)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(activeAgents)="data">
          <span class="text-success">
            {{ data.value }}
          </span>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="displayGameStatusColor(data.value)"
          >
            {{ displayGameStatus(data.value) }}
          </b-badge>
        </template>
        <template #cell(id)="data">
          <b-button
            variant="outline-primary"
            size="sm"
            :to="`/game/setting/${data.value}?slug=${data.item.gameName}`"
          >
            แก้ไข
          </b-button>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            ไม่มีรายการเกม
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-card-footer>
        <b-row class="">
          <b-col md="4">
            <page-limit-select
              :value="limit"
              @update="onLimitChange"
            />
          </b-col>
          <b-col>
            <pagination-input
              :per-page="limit"
              :total="total"
              @update="(val) => (currentPage = val)"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'GameList',
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,

      fields: [
        {
          key: 'gameId',
          label: 'Game ID',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'gameName',
          label: 'ชื่อเกม',
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'activeAgents',
          label: 'ตัวแทนที่ใช้บริการ',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'inactiveAgents',
          label: 'ตัวแทนที่ไม่ใช้บริการ',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'status',
          label: 'สถานะ',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'date',
          label: 'อัพเดทล่าสุด',
          thStyle: {
            minWidth: '220px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.game.isGettingGameHistory,
    }),
    ...mapGetters(['gameHistory']),
    dataList() {
      return ( [
          {
            id: '1',
            gameId: 'GAME00000001',
            gameName: 'เป่ายิงฉุบ',
            activeAgents: 1,
            inactiveAgents: 0,
            status: '',
            date: new Date(),
          },
        ]
      )
    },
    pagination() {
      return {
        itemsPerPage: 0,
        totalItems: 0,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getGameList']),
    fetchData() {
    //   this.getGameList()
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onRowSelected(val) {
      this.$router.push(`/games/setting/${val[0].id}?slug=${val[0].gameName}`)
    },
    displayGameStatusColor(type) {
       switch (type) {
        case `${1}`:
          return 'light-warning'
        case `${2}`:
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    displayGameStatus(type) {
      switch (type) {
        case `${0}`:
         return 'ปิดชั่วคราว'
        case `${1}`:
         return 'ปิดให้บริการ'
        case `${2}`:
         return 'เปิดให้บริการ'
        default:
         return 'เปิดให้บริการ'
     }
   },
  },
}
</script>
